import { CatalogIcon, InfoCard } from '@backstage/core-components';
import { ComponentAccordion, HomePageToolkit } from '@backstage/plugin-home';
import {
  faAws,
  faCloudflare,
  faGoogle,
  faMicrosoft,
} from '@fortawesome/free-brands-svg-icons';
import {
  faBell,
  faBook,
  faBookSkull,
  faFileContract,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';

const ExpandedComponentAccordion = (props: any) => (
  <ComponentAccordion expanded {...props} />
);

const quicklinks = [
  {
    title: 'Essentials',
    renderer: ExpandedComponentAccordion,
    tools: [
      {
        url: '/catalog',
        label: 'Catalog',
        icon: <CatalogIcon />,
      },
      {
        url: '/docs',
        label: 'TechDocs',
        icon: <CatalogIcon />,
      },
      {
        url: '/docs/default/component/advisory-space',
        label: 'Advisory Space',
        icon: <FontAwesomeIcon icon={faFileContract} size="2xl" />,
      },
      {
        url: '/docs/default/component/sondoku/postmortems/',
        label: 'Post Mortems',
        icon: <FontAwesomeIcon icon={faBookSkull} size="2xl" />,
      },
      {
        url: '/docs/default/Component/redbook',
        label: 'Redbook',
        icon: <FontAwesomeIcon icon={faBook} size="2xl" />,
      },
      {
        url: 'https://alarm.staffbase.com',
        label: 'Alarm',
        icon: <FontAwesomeIcon icon={faBell} size="2xl" />,
      },
    ],
  },
  {
    title: 'Cloud Providers',
    renderer: ComponentAccordion,
    tools: [
      {
        url: 'https://staffbase.awsapps.com/start',
        label: 'AWS',
        icon: <FontAwesomeIcon icon={faAws} size="2xl" />,
      },
      {
        url: 'https://console.cloud.google.com',
        label: 'GCP',
        icon: <FontAwesomeIcon icon={faGoogle} size="2xl" />,
      },
      {
        url: 'https://portal.azure.com',
        label: 'Azure',
        icon: <FontAwesomeIcon icon={faMicrosoft} size="2xl" />,
      },
      {
        url: 'https://staffbase.cloudflareaccess.com',
        label: 'Cloudflare',
        icon: <FontAwesomeIcon icon={faCloudflare} size="2xl" />,
      },
    ],
  },
];

export const QuicklinksCard = () => {
  return (
    <InfoCard title="Quicklinks" noPadding>
      <Grid item>
        {quicklinks.map(({ title, tools, renderer }) => (
          <HomePageToolkit
            title={title}
            tools={tools}
            Renderer={renderer || ComponentAccordion}
          />
        ))}
      </Grid>
    </InfoCard>
  );
};
