import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { githubAuthApiRef, googleAuthApiRef } from '@backstage/core-plugin-api';

import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { explorePage } from './components/explore/ExplorePage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import * as plugins from './plugins';

import { staffbaseDarkTheme, staffbaseLightTheme } from './staffbaseTheme';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import { UnifiedThemeProvider } from '@backstage/theme';
import { createTranslationMessages } from '@backstage/core-plugin-api/alpha';
import { adrTranslationRef } from '@backstage-community/plugin-adr/alpha';
import { EngineeringExcellenceScorePage } from '@internal/plugin-engineering-excellence-score';

const authProviders: SignInProviderConfig[] = [
  {
    id: 'github-auth-provider',
    title: 'GitHub',
    message: 'Login via GitHub (recommended)',
    apiRef: githubAuthApiRef,
  },
  {
    id: 'google-auth-provider',
    title: 'Google',
    message: 'Login via Google',
    apiRef: googleAuthApiRef,
  },
];

const app = createApp({
  __experimentalTranslations: {
    availableLanguages: ['en'],
    resources: [
      createTranslationMessages({
        ref: adrTranslationRef,
        messages: {
          contentHeaderTitle: 'Any Decision Records',
        },
      }),
    ],
  },
  apis,
  plugins: Object.values(plugins),
  themes: [
    {
      id: 'staffbase-light-theme',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={staffbaseLightTheme} children={children} />
      ),
    },
    {
      id: 'staffbase-dark-theme',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={staffbaseDarkTheme} children={children} />
      ),
    },
  ],
  components: {
    SignInPage: props => {
      return <SignInPage {...props} providers={authProviders} align="center" />;
    },
  },
  bindRoutes({ bind }) {
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(catalogPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>

    <Route
      path="/catalog"
      element={<CatalogIndexPage initiallySelectedFilter="all" />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome initialFilter="all" />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <Mermaid
          config={{
            theme: 'default',
            themeVariables: { lineColor: '#000000' },
          }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            maxDepth: 2,
            selectedKinds: [
              'api',
              'component',
              'domain',
              'group',
              'product',
              'resource',
              'system',
            ],
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
          showArrowHeads
        />
      }
    />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} pageTitle="" />}
    />
    <Route
      path="/engineering-excellence-score"
      element={<EngineeringExcellenceScorePage />}
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
