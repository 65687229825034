import { HelpIcon, InfoCard } from '@backstage/core-components';
import { List } from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import React from 'react';
import LinkListItem from '../LinkListItem';

export const InterestingReadsCard = () => {
  return (
    <InfoCard title="Interesting Reads" noPadding>
      <List dense>
        <LinkListItem
          primaryText="Product Org Structure"
          secondaryText="Visualization of our department structure"
          link="/docs/default/component/product-org-visualization"
          icon={<AccountTreeIcon />}
        />
        <LinkListItem
          primaryText="Engineering Manifesto"
          secondaryText="Our engineering principles"
          link="/docs/default/component/advisory-space/"
          icon={<MenuBookIcon />}
        />
        <LinkListItem
          primaryText="ADR Template"
          secondaryText="Find here the template for Any-Decision-Records"
          link="/docs/default/component/advisory-space/adrs/principles-processes/0001-adrs/#template"
          icon={<MenuBookIcon />}
        />

        <LinkListItem
          primaryText="GitHub"
          secondaryText="Manage repositories & teams"
          link="/docs/default/component/github/howto/"
          icon={<MenuBookIcon />}
        />
        <LinkListItem
          primaryText="Monitoring / Alerting"
          secondaryText="Add a new alert"
          link="/docs/default/component/redbook/operate-monitor/monitoring-alerting/victoriametrics/#add-a-new-alert"
          icon={<MenuBookIcon />}
        />
        <LinkListItem
          primaryText="Cloud Native Maturity Model"
          secondaryText="Guidance for developing high scalable services"
          link="/docs/default/component/redbook/concepts/cloud-native-maturity"
          icon={<MenuBookIcon />}
        />
        <LinkListItem
          primaryText="Secure Coding Guideline"
          secondaryText="Learn how to develop secure code"
          link="https://mitarbeiterapp.atlassian.net/wiki/spaces/AS/pages/1994784822/Secure+Coding+Guideline"
          icon={<MenuBookIcon />}
        />
        <LinkListItem
          primaryText="Training Area"
          secondaryText="Container Basics and Kubernetes 101"
          link="/docs/default/component/redbook/training/"
          icon={<MenuBookIcon />}
        />
        <LinkListItem
          primaryText="Supported Devices, Operating Systems, and Browsers"
          secondaryText=""
          link="https://support.staffbase.com/hc/en-us/articles/212117729-Supported-Devices-Operating-Systems-and-Browsers"
          icon={<HelpIcon />}
        />
        <LinkListItem
          primaryText="Supported Mobile App Versions"
          secondaryText=""
          link="https://support.staffbase.com/hc/en-us/articles/4414688840594"
          icon={<HelpIcon />}
        />
      </List>
    </InfoCard>
  );
};
